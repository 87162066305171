const Logo = (props) => (
  // <svg
  //   width="786"
  //   height="724"
  //   viewBox="0 0 786 724"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   className={props.class}
  // >
  //   <path
  //     d="M263.789 449V426.441L285.762 422.047V317.604L262.471 313.209V290.504H322.676V422.047L344.502 426.441V449H263.789ZM284.297 254.615V220.484H322.676V254.615H284.297ZM358.857 509.938V487.232L380.244 482.838V317.604L356.953 313.209V290.504H413.35L415.693 309.254C420.674 302.32 426.631 296.998 433.564 293.287C440.596 289.479 448.896 287.574 458.467 287.574C471.357 287.574 482.393 291.09 491.572 298.121C500.752 305.152 507.734 314.967 512.52 327.564C517.402 340.162 519.844 354.908 519.844 371.803V374.879C519.844 390.309 517.402 403.834 512.52 415.455C507.637 426.979 500.605 435.963 491.426 442.408C482.246 448.854 471.26 452.076 458.467 452.076C440.693 452.076 426.875 445.826 417.012 433.326V482.838L439.57 487.232V509.938H358.857ZM447.334 423.658C459.443 423.658 468.379 419.215 474.141 410.328C480 401.344 482.93 389.527 482.93 374.879V371.803C482.93 355.494 479.951 342.262 473.994 332.105C468.135 321.852 459.15 316.725 447.041 316.725C440.205 316.725 434.248 318.287 429.17 321.412C424.092 324.439 420.039 328.736 417.012 334.303V406.959C423.359 418.092 433.467 423.658 447.334 423.658Z"
  //     fill={props.fill}
  //   />
  //   <path
  //     d="M60.3217 360L389.604 29.5976L718.886 360L389.604 690.402L60.3217 360Z"
  //     stroke={props.fill}
  //     strokeWidth="5"
  //   />
  //   <rect
  //     x="363.568"
  //     y="17"
  //     width="52.0726"
  //     height="52.0726"
  //     fill={props.fill}
  //   />
  //   <rect
  //     x="683.927"
  //     y="328.304"
  //     width="52.0726"
  //     height="52.0726"
  //     fill={props.fill}
  //   />
  //   <rect
  //     x="363.568"
  //     y="654.323"
  //     width="52.0726"
  //     height="52.0726"
  //     fill={props.fill}
  //   />
  //   <rect
  //     x="50"
  //     y="333.964"
  //     width="52.0726"
  //     height="52.0726"
  //     fill={props.fill}
  //   />
  // </svg>
  <svg
    width="1400"
    height="1400"
    viewBox="0 0 1400 1400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.class}
  >
    <circle cx="696.5" cy="696.5" r="691.5" stroke="white" strokeWidth="10" />
    <path
      d="M491.4 1008V401.4H458.1V1008H491.4ZM433.8 1008V401.4H400.5V1008H433.8ZM908.592 738.9C1004.89 738.9 1054.39 689.4 1054.39 596.7C1054.39 511.2 997.692 456.3 909.492 456.3H738.492V738.9H908.592ZM771.792 1008V793.8H911.292C1039.09 793.8 1112.89 718.2 1112.89 594.9C1112.89 476.1 1031.89 401.4 912.192 401.4H679.992V1008H713.292V433.8H912.192C1012.09 433.8 1080.49 496.8 1080.49 594.9C1080.49 697.5 1017.49 761.4 911.292 761.4H738.492V1008H771.792ZM771.792 707.4V486.9H909.492C977.892 486.9 1021.09 531 1021.09 596.7C1021.09 666.9 983.292 707.4 908.592 707.4H771.792Z"
      fill={props.textFill}
    />
  </svg>
);

export default Logo;
